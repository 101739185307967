import React from "react";
import { navigate } from "gatsby";

import { LoaderWrap } from "../components/Loader/style";
import Loader from "../components/Loader/Loader";
import { isAuthenticated } from "../utils/auth/auth";

const IndexPage = () => {
  if (isAuthenticated()) {
    navigate(`/auth/explore`);
  }
  return (
    <LoaderWrap>
      <Loader animation="animateToCenter" />
    </LoaderWrap>
  );
};

export default IndexPage;
